import React, { useState } from 'react';
import Container from '../../common/components/UI/Container';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';
import Section, { Content } from './disclaimer.style';

const Disclaimer = () => {

  return (
    <Section>
      <Container>
        <Content>
          <Text content={ <><strong>Disclaimer : </strong> Psychometric tests are based on highly standardized methodology and scientific rigor, which makes it extremely generalizable, however, in no way does it provide an exhaustive persona around a person nor do we claim absolute reliability, the test has its limitations to define a broad concept like management. The results, thus, obtained are for an overall understanding of the self to facilitate better decision-making. For increasing the validity and further consideration of dimensional factors, a mentor consultation for interpreting the assessment and specifying career pathways is recommended.</> } />
        </Content>
      </Container>
    </Section>
  );
};

export default Disclaimer;
