import React from 'react';
import Container from '../../common/components/UI/Container';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';

import { ContactUsWrapper } from './contactUs.style';

const ContactUs = () => {
  return (
    <ContactUsWrapper id="contact_section">
      <Container>
        <Box className="contactInner">
          <Box className="leftContent">
            <Heading as="h3" content={ <><span style={ { color: '#FFCC07' } }>Gain</span> Insights, Solutions, and Expert Guidance <span style={ { color: '#FFCC07' } }>for Your Queries</span></> } />
            <Heading
              as="h4"
              className="color2"
              content="Book a free mentorship session with a 'CAT 100 %tiler'. "
            />
          </Box>
          <Box className="buttonBox">
            <a href="https://bookings.aiexpertsedge.com/admin/mentorship-session" className="contactBtn"> Book the Session Now!</a>
          </Box>
        </Box>
      </Container>
    </ContactUsWrapper>
  );
};

export default ContactUs;
