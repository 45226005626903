import BannerImage from '../common/assets/banner-image-1-1.png';

import FeatureTwoImageOne from '../common/assets/feature-2-1.png';
import FeatureTwoImageTwo from '../common/assets/feature-2-2.png';

import ServiceImageOne from '../common/assets/sectionFour/Problem-Solving Techniques.png';
import ServiceImageTwo from '../common/assets/sectionFour/Verbal Ability Refinement.png';
import ServiceImageThree from '../common/assets/sectionFour/Strategic Time Management.png';
import ServiceImageFour from '../common/assets/sectionFour/Deta Interpretation and Logic.png';
import ServiceImageFive from '../common/assets/sectionFour/Capital Analysis and Synthesis.png';
import ServiceImageSix from '../common/assets/sectionFour/Real-World Application.png';

import TwitterImageOne from '../common/assets/twitter-1-1.png';
import TwitterImageTwo from '../common/assets/twitter-1-2.png';
import TwitterImageThree from '../common/assets/twitter-1-3.png';

import CustomerTrackingImage from '../common/assets/Course Recommendation.png';
import aiExpertsLogo from '../common/assets/ai_experts_logo.png';

import strategic_thinking_image from '../common/assets/heroSection/Strategic-thinking-Hero.png';
import leadership_image from '../common/assets/heroSection/Leadership_Hero.png';
import communication_image from '../common/assets/heroSection/Communication_Hero.png';

import leadership_icon from '../common/assets/sectionTwo/Leadership.png';
import communication_icon from '../common/assets/sectionTwo/Communication.png';
import strategic_icon from '../common/assets/sectionTwo/Strategic Thinking.png';

import leadershipIcon from '../common/assets/heroSection/leadershipIcon.png';
import communicationIcon from '../common/assets/heroSection/communicationIcon.png';
import strategicIcon from '../common/assets/heroSection/strategicIcon.png';

import jitin from '../common/assets/Testimonial Images/Jitesh-Mittal 1.png';
import ishank from '../common/assets/Testimonial Images/Ishank 1.png';
import ishita from '../common/assets/Testimonial Images/Ishita 1.png';
import Radhika from '../common/assets/Testimonial Images/Radhika Pande.png'
import naman from '../common/assets/Testimonial Images/Naman Agarwal.png';
import abhishek from '../common/assets/Testimonial Images/abhishek.png';

export const TESTIMONIALS = [
    {
        title: '',
        review:
            <>Experts Edge provided an exceptional CAT preparation experience. Apt study material, insightful mock tests, and dedicated faculty ensured my success. Joining was the key to my remarkable journey.<br /><br /></>,
        name: 'Jitesh Mittal',
        designation: 'CEO of Dell Co.',
        avatar: jitin,
    },
    {
        title: '',
        review:
            <>Experts Edge surpassed my expectations, offering holistic support. The excellent faculty not only focused on academics but also imparted valuable life lessons, making it an invaluable choice for serious candidates.<br /><br /></>,
        name: 'Ishank',
        designation: 'Co Founder of IBM',
        avatar: ishank,
    },
    {
        title: '',
        review:
            'Grateful to Experts Edge faculty for unwavering support during CAT preparation. Their hard work, encouragement, and innovative teaching methods made my learning journey outstanding. Join for a remarkable adventure.',
        name: 'Ishita',
        designation: 'Manager of Hp co.',
        avatar: ishita,
    },
    {
        title: '',
        review:
            'My transformative experience with Experts Edge emphasized personal growth alongside academic excellence. The faculty\'s focus on broader perspectives, innovative teaching, and constant support made the learning journey enriching and enjoyable.',
        name: 'Radhika',
        designation: 'Co Founder of IBM',
        avatar: Radhika,
    },
    {
        title: '',
        review:
            'Joining Experts Edge was pivotal for my CAT preparation. The relentless commitment of the faculty, personalized approach, and insightful mock tests significantly boosted my confidence and performance. A true partner in success. ',
        name: 'Naman',
        designation: 'Manager of Hp co.',
        avatar: naman,
    },
    {
        title: '',
        review:
            'Experts Edge transformed my CAT preparation journey. The institute\'s holistic approach, combined with dedicated faculty and meticulous mock tests, instilled confidence. Success owed to apt resources and nurturing guidance.',
        name: 'Abhishek',
        designation: 'Manager of Hp co.',
        avatar: abhishek,
    },
];

//import ClientImageOne from '../common/assets/company-1-1.png';
//import ClientImageTwo from 'common/assets/image/sassMinimal/company-1-2.png';
//import ClientImageThree from 'common/assets/image/sassMinimal/company-1-3.png';
//import ClientImageFour from 'common/assets/image/sassMinimal/company-1-4.png';
//import ClientImageFive from 'common/assets/image/sassMinimal/company-1-5.png';


//export const MENU_ITEMS = [
//  {
//    label: 'Home',
//    path: '#banner_section',
//    offset: '70',
//  },
//  {
//    label: 'Feature',
//    path: '#feature_section',
//    offset: '70',
//  },
//  {
//    label: 'Service',
//    path: '#service_section',
//    offset: '70',
//  },
//  {
//    label: 'Pricing',
//    path: '#pricing_section',
//    offset: '70',
//  },
//  {
//    label: 'FAQ',
//    path: '#faq_section',
//    offset: '70',
//  },
//  {
//    label: 'Contact',
//    path: '#contact_section',
//    offset: '70',
//  },
//];

export const BANNER_DATA = [{
    title: `Tool to analyse your \n website performance`,
    text: 'Moment Pro is the best software platform to collect reviews and user-generated content for your business',
    image: BannerImage,
    btnLabel: 'Start 14-days free trail',
    btnLink: '#',
    offerText: '*No Credit card required',
}];

export const FOOTER_DATA = {
    logo: aiExpertsLogo,
    menu: [
        {
            link: '#',
            label: 'Support',
        },
        {
            link: '#',
            label: 'About Us',
        },
        {
            link: '#',
            label: 'Privacy',
        },
        {
            link: '#',
            label: 'Contact',
        },
    ],
    social: [
        {
            icon: aiExpertsLogo,
            link: '#',
        },
    ],
};

//export const COMPANY_DATA = [
//  {
//    image: ClientImageOne,
//  },
//  {
//    image: ClientImageTwo,
//  },
//  {
//    image: ClientImageThree,
//  },
//  {
//    image: ClientImageFour,
//  },
//  {
//    image: ClientImageFive,
//  },
//];

export const FEATURE_DATA = [{
    title: '4200+ Alumni Achievements',
    text: 'Over 4200 alumni have been selected by their dream colleges, showcasing the far-reaching impact of our CAT prep program.',
},
{
    title: '590+ High Scoring Testimonials',
    text: '590+ of our students have been top rankers and attribute their success to our innovative teaching methods and comprehensive study materials.',
},
{
    title: 'Exceptional Success Records',
    text: 'Our alumni boast a 99% success rate in prestigious B-schools, including IIMs, ISB, etc.',
},
{
    title: '300+ Corporate Endorsements',
    text: 'Recognized by over 300 leading corporations, our program is recommended for aspiring managers and future business leaders.',
},
{
    title: '200+ Scholarship Awardees',
    text: 'In the past year alone, 200 of our students received prestigious scholarships for their outstanding CAT scores and academic excellence.',
},
{
    title: 'Industry Recognition',
    text: 'Awarded Best CAT Prep Institute for three consecutive years, reflecting our commitment to quality education and student success.',
},
];

export const CUSTOMER_TRACKING = [{
    tagline: 'Audience source monitoring',
    heading: 'CAT 2024 Preparation Course ',
    content: <> Tailored to enhance your leadership and develop your communication and strategic thinking skills. <br /><br /> <strong>Key Features: </strong>Comprehensive curriculum, expert faculty, and interactive learning modules.</>,
    btnLabel: 'Explore details',
    btnLink: '#',
    image: CustomerTrackingImage,
},];


export const SERVICE_DATA = [{
    image: ServiceImageOne,
    title: 'Problem-Solving Techniques',
    content: 'CAT challenges enhance strategic thinking by teaching you to analyse and solve complex problems from multiple perspectives.',
},
{
    image: ServiceImageTwo,
    title: 'Verbal Ability Refinement',
    content: 'The verbal section improves communication skills through a deep understanding of language and idea articulation.',
},
{
    image: ServiceImageThree,
    title: 'Strategic Time Management',
    content: 'The time constraints in CAT foster quick, strategic decision-making, a vital skill in business environments.',
},
{
    image: ServiceImageFour,
    title: 'Data Interpretation and Logic',
    content: 'Preparing for the DILR section hones strategic thinking skills, essential for data-driven decision making.',
},
{
    image: ServiceImageFive,
    title: 'Critical Analysis and Synthesis',
    content: 'CAT preparation develops the ability to critically analyse information and synthesize persuasive arguments.',
},
{
    image: ServiceImageSix,
    title: 'Real-world Application',
    content: 'The strategic thinking and communication skills gained from CAT preparation are directly applicable and highly valued in professional settings.',
},
];


export const FEATURE_TWO_DATA = [{
    image: FeatureTwoImageOne,
    title: 'Smart Features',
    content: 'We’re driven beyond just finishing the projects. We want to find smart solutions.',
},
{
    image: FeatureTwoImageTwo,
    title: 'Secure Contents',
    content: 'We’re driven beyond just finishing the projects. We want to find smart solutions.',
},
];

export const TWITTER_DATA = [{
    image: TwitterImageOne,
    name: 'Jitesh Mittal',
    designation: 'CEO at Creativex',
    content: 'My experience at Experts edge has been absolutely fantastic. Ranging from the study material and test to the faculty everything is exactly what an aspirants need while preparing for competitive examination, especially for CAT. I was very happy to join this institute which guided me for the preparation and boosted my scores. The mock test and analysis provided by Experts edge were of great help, and faculty assisted me in formulating a strategy that worked for me. The faculty played a very important role in my journey. In short apt resources, amazing faculty, consolidate guidance and a bit of hard work was my Mantra.',
},
{
    image: TwitterImageTwo,
    name: 'Ishank',
    designation: 'CEO at Orbital',
    content: 'This institute has given me more than I wanted for, right from the moment when I stepped in, till today. The faculty here is excellent and I can’t express that in words-they help you not only for scoring well in exams but make you understand life better. The faculty keeps you gripped throughout the process of the exam preparation and support you alway.The institute unique preparation technique from knowing your capabilities through KYC has helped me to prioritize my preparation schedule. Most importantly I will recommend Experts Edge to every serious candidate out there, and if they put in their efforts and listen to the faculties, they will certainly attain success. ',
},
{
    image: TwitterImageThree,
    name: 'Ishita',
    designation: 'Freelance Designer',
    content: 'I would really like to explicit my sincere gratitude to all the faculty of Experts edge for assisting me at each step while getting ready for CAT examinations. The faculty right here are extraordinarily hardworking who will push you when you are slaying off and commend you when you have achieved success. They also teach you to solve quantative ability questions quickly with there “Think without Ink” concept. It’s been an outstanding adventure to be guided through them and learning new techniques and tricks everyday. I will definitely recommend to all the aspirants to join Experts edge and create one of the remarkable journey of your life',
},
];

//export const FOOTER_WIDGET = [
//  {
//    title: 'About Us',
//    menuItems: [
//      {
//        url: '#',
//        text: 'Support Center',
//      },
//      {
//        url: '#',
//        text: 'Customer Support',
//      },
//      {
//        url: '#',
//        text: 'About Us',
//      },
//      {
//        url: '#',
//        text: 'Copyright',
//      },
//    ],
//  },
//  {
//    title: 'Our Information',
//    menuItems: [
//      {
//        url: '#',
//        text: 'Return Policy',
//      },
//      {
//        url: '#',
//        text: 'Privacy Policy',
//      },
//      {
//        url: '#',
//        text: 'Terms & Conditions',
//      },
//      {
//        url: '#',
//        text: 'Site Map',
//      },
//    ],
//  },
//  {
//    title: 'My Account',
//    menuItems: [
//      {
//        url: '#',
//        text: 'Press inquiries',
//      },
//      {
//        url: '#',
//        text: 'Social media',
//      },
//      {
//        url: '#',
//        text: 'directories',
//      },
//      {
//        url: '#',
//        text: 'Images & B-roll',
//      },
//    ],
//  },
//  {
//    title: 'Policy',
//    menuItems: [
//      {
//        url: '#',
//        text: 'Application security',
//      },
//      {
//        url: '#',
//        text: 'Software principles',
//      },
//      {
//        url: '#',
//        text: 'Unwanted software policy',
//      },
//      {
//        url: '#',
//        text: 'Responsible supply chain',
//      },
//    ],
//  },
//];

//// FAQ Section Content
export const FAQ_DATA = [{
    title: 'What are the prerequisites for the CAT 2024 course?',
    description: 'A bachelor\'s degree or equivalent, basic quantitative skills, and a proficiency in English. ',
},
{
    title: 'How can this course help improve my weaker skills?',
    description: 'The course offers personalized coaching and adaptive learning modules to specifically target and enhance your weaker areas. ',
},
{
    expend: true,
    title: 'What career opportunities can I explore after excelling in CAT 2024? ',
    description: 'Successful CAT scorers often pursue lucrative careers in management consulting, investment banking, and strategic roles in top MNCs. ',
},
{
    title: 'How does the course address practical aspects of management?',
    description: 'The course includes case studies, real-world business simulations, and interactive sessions with industry experts. ',
},
{
    title: 'What support is available for course participants during and after the course? ',
    description: 'Continuous mentorship, doubt-clearing sessions during the course, and an active alumni network for post-course career guidance.',
},
];

export const MONTHLY_PRICING_TABLE = [{
    isRecomended: false,
    name: ' Launch Your Management Journey',
    price: 'Conquer IPMAT 2024',
    priceLabel: '',
    buttonLabel: 'Join Our IPMAT Experts',
    url: 'https://bookings.aiexpertsedge.com/admin/mentorship-session',
    listItems: [{
        isDisabled: false,
        content: 'Specialized coaching for IPMAT aspirants. ',
    },
    {
        isDisabled: false,
        content: 'Customized study plans and time management strategies. ',
    },
    {
        isDisabled: false,
        content: 'In-depth material for Quant, Verbal, and Logical Reasoning. ',
    },
    {
        isDisabled: false,
        content: 'Regular mock tests with detailed analysis. ',
    },
    ],
},
{
    isRecomended: true,
    name: 'Your Gateway to Top B-Schools',
    price: 'Master CAT 2024',
    priceLabel: '',
    buttonLabel: 'Start Preparing Now',
    url: 'https://bookings.aiexpertsedge.com/admin/mentorship-session',
    listItems: [{
        isDisabled: false,
        content: 'Comprehensive CAT curriculum covering all sections.',
    },
    {
        isDisabled: false,
        content: 'Advanced analytics to track your progress.',
    },
    {
        isDisabled: false,
        content: 'Live interactive sessions with CAT toppers.',
    },
    {
        isDisabled: false,
        content: 'Access to extensive question banks and mock tests. ',
    },
    ],
},
{
    isRecomended: false,
    name: 'Open Doors to Leading Universities',
    price: 'Ace CUET 2024',
    priceLabel: '',
    buttonLabel: 'Begin Your CUET Journey',
    url: 'https://bookings.aiexpertsedge.com/admin/mentorship-session',
    listItems: [{
        isDisabled: false,
        content: 'Tailored coaching for all CUET subjects.',
    },
    {
        isDisabled: false,
        content: 'Interactive learning modules for effective preparation.',
    },
    {
        isDisabled: false,
        content: 'Expert guidance for General Test and Domain-specific tests.',
    },
    {
        isDisabled: false,
        content: 'Access to mock tests and previous year\'s question papers.',
    },
    ],
},
];

export const skillDefinition =
{
    "Strategic Thinking & Problem-Solving": [{
        "Category": "Strategic Thinking & Problem-Solving",
        "icon": strategicIcon,
        "banner_image": strategic_thinking_image,
        "Top Skill": "Strategic Thinking",
        "Description": "Your high score in strategic thinking reflects your exceptional ability to analyze complex situations, make informed decisions, and foresee long-term implications. In the realm of management, this skill is pivotal for effective planning and problem-solving.",
        "Areas for Improvement": [
            {
                "title": "Leadership",
                "description": "Your leadership abilities are developing. Enhancing this skill will enable you to better motivate teams and lead organizational initiatives.",
                "image": leadership_icon,
            },
            {
                "title": "Communication",
                "description": "Your communication skills require refinement. Effective communication is key to articulating your strategic visions and collaborating effectively.",
                "image": communication_icon,
            }
        ]
    }],
    "Leadership & People Management": [{
        "Category": "Leadership & People Management",
        "icon": leadershipIcon,
        "banner_image": leadership_image,
        "Top Skill": "Leadership",
        "Description": "Your high score in leadership suggests an innate ability to take charge, make decisions, and inspire others. In management, these traits are crucial for team success and organizational growth.",
        "Areas for Improvement": [
            {
                "title": "Communication",
                "description": "Your communication skills are not your strongest suit. Effective communication involves articulating ideas clearly.",
                "image": communication_icon,
            },
            {
                "title": "Strategic Thinking",
                "description": "Your strategic thinking abilities are still developing. Enhancing this skill can lead to more effective decision-making.",
                "image": strategic_icon,
            }
        ]
    },],

    "Communication & Interpersonal Skills": [{
        "Category": "Communication & Interpersonal Skills",
        "icon": communicationIcon,
        "banner_image": communication_image,
        "Top Skill": "Communication",
        "Description": "Your exceptional score in communication highlights your natural ability to articulate ideas clearly and effectively, a crucial skill in management for engaging teams and stakeholders.",
        "Areas for Improvement": [
            {
                "title": "Leadership",
                "description": "Your leadership skills are in the development phase. Enhancing this area will enable you to better direct teams and lead initiatives.",
                "image": leadership_icon,
            },
            {
                "title": "Strategic Thinking",
                "description": "Your strategic thinking abilities are evolving. Focusing on this skill will improve your capacity for long-term planning and decision-making.",
                "image": strategic_icon,
            }
        ]
    }]
}
