import React, { Fragment, useEffect, useState } from 'react';
//import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { sassMinimalTheme } from '../common/theme/index';
import ResetCSS from '../common/style';
import { GlobalStyle, ContentWrapper } from '../components/aiexpertsedge.style';
import BannerSection from '../components/BannerSection/index';
//import Navbar from 'containers/SassMinimal/Navbar';
//import Company from 'containers/SassMinimal/Company';
import FeatureSection from '../components/FeatureSection/index';
import CustomerTracking from '../components/CustomerTracking';
import ServiceSection from '../components/ServiceSection/index';
import FeatureTwoSection from '../components/FeatureTwoSection/index';
import TwitterSection from '../components/TwitterSection/index';
//import { DrawerProvider } from '../common/contexts/DrawerContext';
import Pricing from '../components/Pricing/index';
import FaqSection from '../components/Faq/index';
import ContactUs from '../components/ContactUs';
//import Footer from 'containers/SassMinimal/Footer';
import Disclaimer from '../components/Disclaimer/index';
import Footer from '../components/Footer';
import useFetchAssessmentScore from '../api/FetchAssessmentScore';
import { skillDefinition } from '../data';
import ReactLoading from 'react-loading';
import TestimonialSection from '../components/Testimonial';

const AiExpertsEdge = () => {

  const [userName, setUserName] = useState( null );
  const [userId, setUserId] = useState( null );

  useEffect( () => {
    const url = new URL( window.location.href );

    const userNameParam = url.searchParams.get( 'userName' );
    const user_id = url.searchParams.get( 'userId' );

    setUserName( userNameParam );
    setUserId( user_id );

  }, [] );

  console.log( 'userName:', userName );
  console.log( 'productCode:', userId );


  const { data: AssessmentData, isLoading: isAssessmentDataLoading, isError: isAssessmentDataError } = useFetchAssessmentScore( userId );

  const targetName = "LEADERSHIP STRATEGIC COMMUNICATION";

  const getSegmentName = ( AssessmentData, targetName ) => {
    if ( !isAssessmentDataLoading ) {
      const data = AssessmentData?.assessment_survey_score || [];
      for ( const item of data ) {
        if ( item.name === targetName ) {
          return item.segmentation_name || item.segmentation;
        }
      }
      return null;
    }
  };

  const segmentName = getSegmentName( AssessmentData, targetName );

  const getSegmentInformation = ( skillDefinition, highSkill ) => {
    for ( const [category, skills] of Object.entries( skillDefinition ) ) {
      for ( const skill of skills ) {
        if ( skill.Category.toLowerCase() === highSkill?.toLowerCase() ) {
          return {
            "Top Skill": skill["Top Skill"],
            "Description": skill["Description"],
            "Areas for Improvement": skill["Areas for Improvement"],
            "Banner Image": skill["banner_image"],
            "icon": skill["icon"],
          };
        }
      }
    }
    return null;
  };

  const formatDateTime = ( datetimeString ) => {
    const datetime = new Date( datetimeString );

    const optionsDate = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const dateFormatted = datetime.toLocaleDateString( 'en-GB', optionsDate );

    const optionsTime = {
      hour: '2-digit',
      minute: '2-digit',
    };
    const timeFormatted = datetime.toLocaleTimeString( 'en-US', optionsTime );

    return { date: dateFormatted, time: timeFormatted };
  }

  const date = AssessmentData?.created_at;
  const formattedDateTime = formatDateTime( date );

  console.log( 'formatDateTime', formattedDateTime )

  const segmentInformation = getSegmentInformation( skillDefinition, segmentName );

  return (
    <ThemeProvider theme={ sassMinimalTheme }>
      {
        isAssessmentDataLoading ?
          <>
            <div
              style={ {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              } }
            >
              <ReactLoading type='spokes' color='#000000' height={ 64 } width={ 64 } />
            </div>
          </>
          :
          <>
            <Fragment>
              {/*<Head>
          <title>AiExpertsEdge | A react next landing page</title>
          <meta name="Description" content="React next landing page" />
          <meta name="theme-color" content="#ec5555" />

          <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700" rel="stylesheet" />
        </Head>*/}
              <ResetCSS />
              <GlobalStyle />
              <ContentWrapper>
                {/*<Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>*/}
                <BannerSection segmentInformation={ segmentInformation } user={ userName } userID={ userId } date={ formattedDateTime } />
                <FeatureTwoSection segmentInformation={ segmentInformation } />
                <Pricing />
                <ServiceSection />
                <FeatureSection />
                <TestimonialSection />
                <CustomerTracking />
                <ContactUs />
                <FaqSection />
                <Disclaimer />
                <Footer />
                {/*<Company />
          <FeatureSection />
          <CustomerTracking />
          <ServiceSection />
          <FeatureTwoSection />
          <TwitterSection />
          <Pricing />
          <FaqSection />
          <ContactUs />
          <Footer />*/}
              </ContentWrapper>
            </Fragment>
          </>
      }
    </ThemeProvider>
  );
};

export default AiExpertsEdge;
