import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import { FOOTER_DATA } from '../../data/index';
import React from 'react';
import FooterArea from './footer.style';

const Footer = () => {
  const { logo, menu, social } = FOOTER_DATA;
  return (
    <FooterArea>
      <Container>
        <Box className="logoBox">
          <a className="logo">
            <img src={ logo } style={ { width: '110px', height: '62px' } } alt="logo footer" />
          </a>
          {/*<Text as="p" content={ `Copyright © ${ new Date().getFullYear() }` } />
          <a className="footerLink">RedQ, Inc.</a>*/}
        </Box>
        {/*<Box className="menu">
          { menu.map( ( { link, label }, index ) => (
            <a className="menuLink">{ label }</a>
          ) ) }
        </Box>*/}
        <Box className="social">
          <Text as="span" content="All Rights Reserved 2024 © Aiexpertsedge Pvt ltd" />
        </Box>
      </Container>
    </FooterArea>
  );
};

export default Footer;
