import React, { useState, useEffect } from 'react';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import Fade from 'react-reveal/Fade';
import { Image } from 'antd';
import { FeatureTwoWrapper } from './featureTwo.style';

import FeatureTwoMoc from '../../common/assets/sectionTwo/Frame.png';

const FeatureTwoSection = ( { segmentInformation } ) => {

  const [areaOfImprovement, setAreaOfImprovement] = useState();

  console.log( 'areaOfImprovement', areaOfImprovement )
  
  useEffect( () => {

    const AOI = segmentInformation["Areas for Improvement"]
    setAreaOfImprovement( AOI );

  }, [segmentInformation] )

  return (
    <FeatureTwoWrapper>
      <Container>
        <Box className="row">
          <Box className="column">
            <Box className="blockTitle">
              <Heading
                as="h2"
                content="Areas for Improvement"
              />
            </Box>
            <Box className="featureTwoContent">
              { areaOfImprovement?.map( ( improvement, index ) => (
                <Box className="featureTwoBox" key={ `improvement-${ index }` }>
                  <img src={ improvement.image } alt={ `Improvement ${ index }` } />
                  <Heading as="h3" content={ improvement.title } />
                  <Text as="p" content={ improvement.description } />
                </Box>
              ) ) }
            </Box>
          </Box>
          <Box className="column">
            <Fade right>
              <Image src={ FeatureTwoMoc } alt="feature two moc" preview={ false } />
            </Fade>
          </Box>
        </Box>
      </Container>
    </FeatureTwoWrapper>
  );
};

export default FeatureTwoSection;
