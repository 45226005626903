import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FETCH_SCORE } from '../utils/constants';

const useFetchAssessmentScore = ( id ) => {
    const [data, setData] = useState( null );
    const [isError, setIsError] = useState( false );
    const [isLoading, setIsLoading] = useState( true );
    const isFirstMount = useRef( true ); // Initialize a useRef

    useEffect( () => {
        if ( isFirstMount.current ) { // Check if it's the first mount
            if ( id ) { // Ensure id is available
                console.log( 'ID', id );
                console.log( 'FETCH_SCORE', FETCH_SCORE );

                const fetchData = async () => {
                    setIsLoading( true );
                    try {
                        const response = await axios.get( `${ FETCH_SCORE }?id=${ id }&client=aiee` );
                        setData( response.data );
                    } catch ( error ) {
                        setIsError( true );
                    } finally {
                        setIsLoading( false );
                    }
                };

                fetchData();

                isFirstMount.current = false; // Set it to false after the first valid API call
            }
        }
    }, [id] ); // Dependency on id, so the effect runs whenever id changes

    return { data, isLoading, isError };
};

export default useFetchAssessmentScore;