import styled from 'styled-components';

export const DiscountWrapper = styled.div `
  text-align: center;
`;

export const DiscountLabel = styled.div `
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  border-radius: 4em;
  padding: 9px 22px;
  margin-bottom: 22px;
  @media (max-width: 575px) {
    padding: 7px 10px;
  }
`;



export const BannerWrapper = styled.section `
  position: relative;
  width: 100%;
  padding-top: 70px;
  background-image: linear-gradient(131deg, #294686 0%, #6692CB 38%, #6895CE 99%);
  margin-bottom: 135px;

  @media (max-width: 767px) {
    padding-top: 30px;
  }

  @media (max-width: 480px) {
    margin-bottom: 0;
    padding-bottom: 0px;
  }

  .container {
    width: 100%;
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;

    > span {
      font-family: DM Sans;
      font-style: normal;
      font-weight: 500;
      display: block;
      color: #fff;
      opacity: 0.6;
      font-size: 15px;
      margin-top: 15px;
    }
  }

  h2 {
    margin: 0;
    color: #020201;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 70px;
    margin-left: auto;
    margin-right: auto;
    white-space: pre-line;
    display : flex;
    justify-content : center;
    align-items:center;
    margin-top : 10px;
    margin-bottom:10px;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 1.2;
    }

    @media (max-width: 1024px) {
      font-size: 45px;
      line-height: 1.2;
    }

    @media (max-width: 690px) {
      font-size: 40px;
      line-height: 1.2;
      width: 70%;
      white-space: normal;
    }

    @media (max-width: 575px) {
      margin-top : 20px;
      margin-bottom : 20px;
      font-size: 15px;
      line-height: 1.2em;
      width: 80%;
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }

  h3 {
    margin: 0;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 62px;
    line-height: 70px;
    margin-left: auto;
    margin-right: auto;
    white-space: pre-line;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 1.2;
    }

    @media (max-width: 1024px) {
      font-size: 45px;
      line-height: 1.2;
    }

    @media (max-width: 690px) {
      font-size: 40px;
      line-height: 1.2;
      width: 70%;
      white-space: normal;
    }

    @media (max-width: 575px) {
      font-size: 35px;
      line-height: 1.2em;
      width: 80%;
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }

  h4 {
    margin: 0;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 70px;
    margin-left: auto;
    margin-right: auto;
    white-space: pre-line;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 1.2;
    }

    @media (max-width: 1024px) {
      font-size: 45px;
      line-height: 1.2;
    }

    @media (max-width: 690px) {
      font-size: 40px;
      line-height: 1.2;
      width: 70%;
      white-space: normal;
    }

    @media (max-width: 575px) {
      margin-top : 15px;
      margin-bottom : 20px;
      font-size: 12px;
      line-height: 1.2em;
      width: 80%;
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }

  p {
    margin: 0;
    color: #fff;
    opacity: 0.8;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.88;
    letter-spacing: -0.3px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;

    @media (max-width: 1024px) {
      width: 48%;
    }

    @media (max-width: 690px) {
      width: 55%;
    }

    @media (max-width: 575px) {
      width: 65%;
      line-height: 1.5;
    }

    @media (max-width: 425px) {
      width: 80%;
    }
  }
  .imageWrapper {
    display: inline-block;
    position: relative;
    bottom: -22px;
    margin-top: -115px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    @media (max-width: 480px) {
      bottom: 0;
      margin-top: -30px;
      margin-bottom: -21px;
    }
  }
  .reusecore__button {
    background-color: #FFCC02;
    padding: 17.5px 20px;
    border-radius: 5px;
    transition: 500ms;
    position: relative;
    z-index: 10;

    span {
      font-family: DM Sans;
      color: black;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      display: flex;
      align-items: center;
    }
  }
`;