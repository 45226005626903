import React from 'react';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import { FeatureSectionWrapper } from './featureSection.style';
import FeatureCheckImage from '../../common/assets/check.png';

import { FEATURE_DATA } from '../../data/index';

const FeatureSection = () => {
  return (
    <FeatureSectionWrapper id="feature_section">
      <Container>
        <Box className="blockTitle">
          <Heading as="h2" content="CAT 2024: Sharpening Communication & Strategic Thinking Skills" />
          <Text as="p" content="Empowering Aspirants to Excel: Our Proven Track Record" />
        </Box>
        <Box className="row">
          { FEATURE_DATA.map( ( feature, index ) => (
            <Box className="featureSingle" key={ `feature-one-${ index }` }>
              <Box className="featureSingleInner">
                <img src={ FeatureCheckImage } alt="Feature check Image" />
                <Heading as="h3" content={ feature.title } />
                <Text as="p" content={ feature.text } />
              </Box>
            </Box>
          ) ) }
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

export default FeatureSection;
