import React, { useState, useEffect } from 'react';
// import Link from 'next/link';
import Box from '../../common/components/Box/index';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';
import Button from '../../common/components/Button';
import Container from '../../common/components/UI/Container';
import Fade from 'react-reveal/Fade';
import logo from '../../common/assets/ai_experts_logo.png';
import { BannerWrapper, DiscountWrapper, DiscountLabel } from './bannerSection.style';
import useFetchAssessmentScore from '../../api/FetchAssessmentScore';
import { Image } from 'antd';
import { BANNER_DATA, skillDefinition } from '../../data/index';

const BannerSection = ( { segmentInformation, user, userID, date } ) => {

  const [topSkill, setTopSkill] = useState();
  const [topSkillDescription, setTopSkillDescription] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [icon, setIcon] = useState();

  console.log( 'segmentInformation', segmentInformation );

  useEffect( () => {

    if ( segmentInformation ) {
      const topSkill = segmentInformation["Top Skill"];
      setTopSkill( topSkill )
      const topSkillDescription = segmentInformation["Description"];
      setTopSkillDescription( topSkillDescription )
      const banner_image = segmentInformation["Banner Image"];
      setBannerImage( banner_image )
      const segmentation_icon = segmentInformation["icon"];
      setIcon( segmentation_icon )
    }

  }, [segmentInformation] )

  return (
    <BannerWrapper id="banner_section">
      <DiscountWrapper>
        <DiscountLabel>
          <img src={ logo } style={ { width: '110px', height: '62px' } } />
        </DiscountLabel>
      </DiscountWrapper>
      { BANNER_DATA.map( ( banner, index ) => (
        <Container key={ `banner-${ index }` }>
          <Heading content={ <>Hi, <span style={ { color: '#FFCC02' } }>{ user }</span></> } as="h3" />
          <Heading content={ <>User ID : { userID } <span style={ { marginLeft: '30px' } }>Date of Completion :</span> { date.date }</> } as="h4" />
          <a>
            <Button title="Your Personalized Report!" />
          </a>
          <Heading content={ <>Your Top Skill : <img src={ icon } />{ topSkill }</> } as="h2" />
          <Text content={ topSkillDescription } />
          <Fade bottom>
            <Box className="imageWrapper">
              <Image src={ bannerImage } alt="Banner Image" preview={ false } />
            </Box>
          </Fade>
        </Container>
      ) ) }
      ;
    </BannerWrapper>
  );
};

export default BannerSection;
